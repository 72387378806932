import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import basicsStyle from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.jsx";
import Card from "components/Card/Card.jsx";
import Carousel from "react-slick";

class ProjectImagesCarousel extends React.Component {
  // constructor(props) {
  //   super(props);
  // }
  componentDidMount() {

  }

  handleProjectImageClicked(image, index, images){
    // console.log(`handleProjectImageClicked - image: ${image}, index: ${index}`);
    if(this.props.handleProjectImageClicked){
      this.props.handleProjectImageClicked(image, index, images);
    }
  }

  render() {
    const { images } = this.props;
    let viewImages = [];
    if(images && images.length){
      viewImages = images.map((img) => {
        let viewImg = Object.assign({}, img);
        viewImg.src = img.srcView ? img.srcView : img.src;
        return viewImg;
      })
    }

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false
    };

    return (
      <Card carousel>
        <Carousel {...settings}>
          {images && images.length && images.map((image, index) => {
            return (
              <div onClick={(e)=> this.handleProjectImageClicked.bind(this)(image, index, viewImages)} key={index}>
                <img
                  src={image.src}
                  alt={image.alt}
                  className="slick-image"
                />
                {image.showCaption && <div className="slick-caption">
                  <h4>
                    {image.desc}
                  </h4>
                </div>}
              </div>
            )
          })}
        </Carousel>
      </Card>
    );
  }
}

export default withStyles(basicsStyle)(ProjectImagesCarousel);
