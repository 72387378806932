import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.4.0";
import "assets/scss/custom.scss?v=1.4.0";

// pages for this product
import Components from "views/Components/Components.jsx";
import CommercialProjects from "views/Components/CommercialProjects";
import MyOwnProjects from "views/Components/MyOwnProjects"
import LicenceCertificates from 'views/Components/LicenceCertificates';
// import LandingPage from "views/LandingPage/LandingPage.jsx";
// import ProfilePage from "views/ProfilePage/ProfilePage.jsx";
// import LoginPage from "views/LoginPage/LoginPage.jsx";

var hist = createBrowserHistory();
require('es6-promise').polyfill();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      {/*<Route path="/landing-page" component={LandingPage} />
      <Route path="/profile-page" component={ProfilePage} />
      <Route path="/login-page" component={LoginPage} />*/}
      <Route path="/commercial-projects" component={CommercialProjects} />
      <Route path="/my-own-projects" component={MyOwnProjects}/>
        <Route path="/licences-certificates" component={LicenceCertificates}/>
      <Route path="/" component={Components} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
