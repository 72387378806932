import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import classNames from "classnames";
import basicsStyle from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.jsx";
import imgCrewStudio1_h from "assets/img/crewstudio/crewstudio_app_login_h.jpg";
import imgCrewStudio2_h from "assets/img/crewstudio/crewstudio_app_task_h.jpg";
import imgCrewStudio3_h from "assets/img/crewstudio/crewstudio_app_custom_camera_h.jpg";
import imgCrewStudio4_h from "assets/img/crewstudio/crewstudio_app_video_preview_h.jpg";
import imgCrewStudio1 from "assets/img/crewstudio/crewstudio_app_login.jpg";
import imgCrewStudio2 from "assets/img/crewstudio/crewstudio_app_task.jpg";
import imgCrewStudio3 from "assets/img/crewstudio/crewstudio_app_custom_camera.jpg";
import imgCrewStudio4 from "assets/img/crewstudio/crewstudio_app_video_preview.jpg";
import imgUnicef from "assets/img/crewstudio/unicef_app_login.jpg";
import imgUnicef_h from "assets/img/crewstudio/unicef_app_login_h.jpg";
import imgIoic from "assets/img/crewstudio/ioic_app_login.jpg";
import imgIoic_h from "assets/img/crewstudio/ioic_app_login_h.jpg";

import imgBoFe from "assets/img/traderfx/bo_fe.png";
import imgMypage1 from "assets/img/traderfx/mypage-02.png";
import imgMypage2 from "assets/img/traderfx/mypage-03.png";
import imgBoBe from "assets/img/traderfx/bobe.png";
import imgDealing from "assets/img/traderfx/dealingadmin.png";

import imgAmnestyDecoder1 from "assets/img/amnesty/amnesty-decoder-1.png";
import imgAmnestyDecoder2 from "assets/img/amnesty/amnesty-decoder-2.png";

import imgBidmea1 from "assets/img/bidmea/bidmea-1.png";
import imgBidmea2 from "assets/img/bidmea/bidmea-2.png";
import imgBidmea3 from "assets/img/bidmea/bidmea-3.png";

import imgCw1 from "assets/img/craftworld/cw_inspiration_gallery.png";
// import imgCw2 from "assets/img/craftworld/cw_offer_and_comp.png";
import imgCw3 from "assets/img/craftworld/cw_article_details.png";
import imgCw3_h from "assets/img/craftworld/cw_article_details_h.png";
import imgCw5 from "assets/img/craftworld/cw_article_list.png";
import imgCw4 from "assets/img/craftworld/cw_article_editing.png";
import imgCw6 from "assets/img/craftworld/cw_article_stats.png";

import imgCw7 from "assets/img/craftworld/cw_craftpages.png";
import imgCw8 from "assets/img/craftworld/cw_dashboard.png";
import imgCw9 from "assets/img/craftworld/cw_user_dashboard.png";
import imgCw9_h from "assets/img/craftworld/cw_user_dashboard_h.png";
import imgCw10 from "assets/img/craftworld/cw_chatpage.png";
import imgCw10_h from "assets/img/craftworld/cw_chatpage_h.png";

import imgCw11 from "assets/img/craftworld/cw_forum_discussion_topic.png";
import imgCw11_h from "assets/img/craftworld/cw_forum_discussion_topic_h.png";
import imgCw12 from "assets/img/craftworld/cw_events.png";

import imgWateraid1 from "assets/img/wateraid-sanitation/wateraid_1-min.png";
import imgWateraid2 from "assets/img/wateraid-sanitation/wateraid_2-min.png";
import imgWateraid3 from "assets/img/wateraid-sanitation/wateraid_3-min.png";
import imgWateraid3_h from "assets/img/wateraid-sanitation/wateraid_3-min_h.png";
import imgWateraid4 from "assets/img/wateraid-sanitation/wateraid_4-min.png";
import imgWateraid4_h from "assets/img/wateraid-sanitation/wateraid_4-min_h.png";

// import image1 from "assets/img/bg.jpg";
// import image2 from "assets/img/bg2.jpg";
// import image3 from "assets/img/bg3.jpg";
import NavPills from "components/NavPills/NavPills.jsx";
import ProjectImagesCarousel from './Partials/ProjectImagesCarousel'
import Carousel, { Modal, ModalGateway } from "react-images";

class SectionProjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewerIsOpen: false
    };
  }
  componentDidMount() {
  }

  handleProjectImageClicked(image, index, images){
    // console.log(`handleProjectImageClicked - image: ${image}, index: ${index}, images: `, images);
    this.setState({
      photos: images,
      currentImageIndex: index,
      viewerIsOpen: true
    })
  }

  closeLightbox() {
    this.setCurrentImage.bind(this)(0);
    this.setViewerIsOpen.bind(this)(false);
  };

  setCurrentImage(imgindex){
    this.setState({
      currentImageIndex: imgindex
    })
  }

  setViewerIsOpen(opened){
    this.setState({
      viewerIsOpen: opened
    })
  }

  render() {
    const { classes } = this.props;
    const { photos, currentImageIndex } = this.state;
    let viewerIsOpen = this.state.viewerIsOpen;
    // console.log(`the photos in renders `, photos)
    if(!photos || !photos.length){
      viewerIsOpen = false;
    }

    // console.log(`the photos in renders - viewerIsOpen: ${viewerIsOpen}, currentImageIndex: ${currentImageIndex}`)

    return (
      <div className={classNames(classes.sections, 'skillset-wrapper')}>
        <div className={classes.container}>
          <div className={classes.title}>
            <h2>Commercial Projects I joined</h2>
          </div>

          <div id="progress">
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.title}>
                    <h3>Wateraid program - Sanitation</h3>
                </div>
                <ProjectImagesCarousel
                    handleProjectImageClicked={this.handleProjectImageClicked.bind(this)}
                    images={[
                        {
                            src: imgWateraid1,
                            srcView: imgWateraid1,
                            alt: 'Wateraid Sanitation Work',
                            desc: 'Wateraid Sanitation Work - Camera 360 scene'
                        },
                        {
                            src: imgWateraid2,
                            srcView: imgWateraid2,
                            alt: 'Wateraid Sanitation Work',
                            desc: 'Wateraid Sanitation Work - Camera 360 scene'
                        },
                        {
                            src: imgWateraid3_h,
                            srcView: imgWateraid3,
                            alt: 'Wateraid Sanitation Work',
                            desc: 'Wateraid Sanitation Work - Camera 360 scene'
                        },
                        {
                            src: imgWateraid4_h,
                            srcView: imgWateraid4,
                            alt: 'Wateraid Sanitation Work',
                            desc: 'Wateraid Sanitation Work - Camera 360 scene'
                        },
                    ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <NavPills
                    color="primary"
                    tabs={[
                        {
                            tabButton: "Overview",
                            // tabIcon: Dashboard,
                            tabContent: (
                                <span>
                      <p>
                        This is a call to action program to help people in Arfica getting pure water for their daily life basis.
                      </p>
                      <br />
                      <p>
                        In order to enhance the experience of users, we used camera 360 for rendering images on site.
                      </p>
                      <br/>
                      <p>
                        <a href={'https://sanitationwork.wateraid.org/'} target={'_blank'} >Experience it yourself here</a>
                      </p>
                    </span>
                            )
                        },
                        {
                            tabButton: "Technologies",
                            // tabIcon: Schedule,
                            tabContent: (
                                <span>
                      <ul>
                        <li>ThreeJS</li>
                      </ul>
                    </span>
                            )
                        }
                    ]}
                />
            </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Craft World</h3>
                </div>
                <ProjectImagesCarousel
                  handleProjectImageClicked={this.handleProjectImageClicked.bind(this)}
                  images={[
                    {
                      src: imgCw1,
                      srcView: imgCw1,
                      alt: 'Inspiration Gallery',
                      desc: 'A collection of craft content made by users in community'
                    },{
                      src: imgCw3_h,
                      srcView: imgCw3,
                      alt: "Content Detail",
                      desc: 'An user content is shown and teach people to build their crafting skill.'
                    },{
                      src: imgCw4,
                      srcView: imgCw4,
                      alt: "Content Editor",
                      desc: 'A content editor is intuitive and easy to use with lots of cards by customizing mobiledoc kit editor.'
                    },{
                      src: imgCw5,
                      srcView: imgCw5,
                      alt: "Content list",
                      desc: "User could manage their own contents or craft page's content which they manage"
                    },{
                      src: imgCw6,
                      srcView: imgCw6,
                      alt: "Content Statistics",
                      desc: 'A view for system admin to see current state of user content'
                    },{
                      src: imgCw7,
                      srcView: imgCw7,
                      alt: "Craft Page",
                      desc: 'The way people could publish their content under a particular brand'
                    },{
                      src: imgCw8,
                      srcView: imgCw8,
                      alt: "Dashboard",
                      desc: 'Where user can see news feed from activities on system'
                    },{
                      src: imgCw9_h,
                      srcView: imgCw9,
                      alt: "User dashboard",
                      desc: 'User profile page, this one is a personal space where a certain user has full control on his own wall feeds'
                    },{
                      src: imgCw10_h,
                      srcView: imgCw10,
                      alt: "Chat Page",
                      desc: 'Gather the forum information where people could join and discuss about various topics'
                    },{
                      src: imgCw11_h,
                      srcView: imgCw11,
                      alt: "Forum Topic",
                      desc: 'A thread of discussion on a particular topic'
                    },{
                      src: imgCw12,
                      srcView: imgCw12,
                      alt: "Events",
                      desc: 'List up all events which will happens online or on location'
                    }
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <NavPills
                  color="primary"
                  tabs={[
                    {
                      tabButton: "Overview",
                      // tabIcon: Dashboard,
                      tabContent: (
                        <span>
                          <p>
                            Craft World is a modern social network for crafting stuffs. A friendly online community for all papercrafters to come together, be inspired and share their love for crafting!
                          </p>
                          <br />
                          <p>
                            CraftWorld is designed by crafters for crafters and at the heart of CraftWorld you will find lively discussion, practical advice and like-minded crafters.
                          </p>
                          <br/>
                          <p>
                            So, come on in and start belonging to this friendly community that is <a href={'https://craftworld.com'} target={'_blank'} >your CraftWorld</a>!
                          </p>
                        </span>
                      )
                    },
                    {
                      tabButton: "Technologies",
                      // tabIcon: Schedule,
                      tabContent: (
                        <span>
                          <ul>
                            <li>PHP (Yii2)</li>
                            <li>EmberJS</li>
                            <li>NodeJS (ExpressJS)</li>
                            <li>AWS S3</li>
                            <li>MySQL</li>
                            <li>Elastic Search</li>
                            <li>Kubernetes</li>
                            <li>Circle CI</li>
                          </ul>
                        </span>
                      )
                    }
                  ]}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>CrewStudio</h3>
                </div>
                <ProjectImagesCarousel
                  handleProjectImageClicked={this.handleProjectImageClicked.bind(this)}
                  images={[
                    {
                      src: imgCrewStudio1_h,
                      srcView: imgCrewStudio1,
                      alt: 'App Login Screen',
                      desc: 'App login screen with interating to Oauth2 API server'
                    },{
                      src: imgCrewStudio2_h,
                      srcView: imgCrewStudio2,
                      alt: "App Task Screen",
                      desc: 'Tasks are fetched based on a particular project'
                    },{
                      src: imgCrewStudio3_h,
                      srcView: imgCrewStudio3,
                      alt: "App Video Capture",
                      desc: 'Video is recorded by React Native Camera customized, decorated with silhouette'
                    },{
                      src: imgCrewStudio4_h,
                      srcView: imgCrewStudio4,
                      alt: "App Video Preview",
                      desc: 'Video is saved to app storage and could be previewed'
                    },{
                      src: imgUnicef_h,
                      srcView: imgUnicef,
                      alt: "Unicef app variant",
                      desc: 'UNICEF chooses to use the platform'
                    },{
                      src: imgIoic_h,
                      srcView: imgIoic,
                      alt: "IoIC app variant",
                      desc: 'IoIC chooses to use the platform'
                    }
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <NavPills
                  color="primary"
                  tabs={[
                    {
                      tabButton: "Overview",
                      // tabIcon: Dashboard,
                      tabContent: (
                        <span>
                          <p>
                            CREWSTUDIO is the award-winning video creation app from wtv. used daily by many of the world’s leading organisations.
                          </p>
                          <br />
                          <p>
                            Let go to the <a href={'https://www.crewstudio.com'} target={'_blank'}>home page</a> and see how the platform works.
                          </p>
                          <p>
                            The platform is designed for multi-tenants, and now the service are being used by famous organizations
                          </p>
                          <ul>
                            <li><a href={'https://www.unicef.org/'} target={'_blank'}>UNICEF</a> (try their UNICEF Studio on <a href={'https://play.google.com/store/apps/details?id=com.unicefstudio.app'} target={'_blank'}>Android</a> and <a href={'https://apps.apple.com/us/app/unicef-studio/id1461122408'} target={'_blank'}>IOS</a>)</li>
                            <li><a href={'https://www.wtvglobal.com/'} target={'_blank'}>WTV Global</a> (try their CrewStudio on <a href={'https://play.google.com/store/apps/details?id=com.crewstudio.app'} target={'_blank'}>Android</a> and <a href={'https://apps.apple.com/us/app/crewstudio/id1398899998'} target={'_blank'}>IOS</a>)</li>
                            <li><a href={'https://www.ioic.org.uk/'} target={'_blank'}>IoIC</a> (try their IoIC Studio on <a href={'https://play.google.com/store/apps/details?id=com.ioicstudio.app'} target={'_blank'}>Android</a> and <a href={'https://apps.apple.com/us/app/ioic-studio/id1462620836'}>IOS</a>)</li>
                          </ul>
                        </span>
                      )
                    },
                    {
                      tabButton: "Technologies",
                      // tabIcon: Schedule,
                      tabContent: (
                        <span>
                          <ul>
                            <li>React Native</li>
                            <li>ReactJS</li>
                            <li>NodeJS (ExpressJS)</li>
                            <li>AWS S3</li>
                            <li>AWS Elasticbeanstalk</li>
                            <li>MongoDB</li>
                            <li>Circle CI</li>
                          </ul>
                        </span>
                      )
                    }
                  ]}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Amnesty Decoder</h3>
                </div>
                <ProjectImagesCarousel
                  handleProjectImageClicked={this.handleProjectImageClicked.bind(this)}
                  images={[
                      {
                          src: imgAmnestyDecoder1,
                          srcView: imgAmnestyDecoder1,
                          alt: "Register Page",
                          desc: 'Feel free to register an account to help people around the world'
                      },{
                          src: imgAmnestyDecoder2,
                          srcView: imgAmnestyDecoder2,
                          alt: "Task Page",
                          desc: 'Help people by resolving given tasks'
                      }
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <NavPills
                  color="primary"
                  tabs={[
                    {
                      tabButton: "Overview",
                      // tabIcon: Dashboard,
                      tabContent: (
                        <span>
                        <p>
                          Amnesty Decoders is an innovative platform for volunteers around the world to use
                          their computers or phones to help oganization's researchers sift through pictures, information and documents.
                        </p>
                        <br />
                        <p>
                          <a href={'https://decoders.amnesty.org/register'} target={'_blank'}>Register</a> to help people around the world.
                          Thank to your great contribution.
                        </p>
                      </span>
                      )
                    },
                    {
                      tabButton: "Technologies",
                      // tabIcon: Schedule,
                      tabContent: (
                        <span>
                        <ul>
                          <li>ReactJS</li>
                          <li>NodeJS</li>
                          <li>Elastic Search</li>
                          <li>Golang</li>
                          <li>MySQL</li>
                          <li>Circle CI</li>
                        </ul>
                      </span>
                      )
                    }
                  ]}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Bidmea</h3>
                </div>
                <ProjectImagesCarousel
                  handleProjectImageClicked={this.handleProjectImageClicked.bind(this)}
                  images={[
                      {
                          src: imgBidmea1,
                          srcView: imgBidmea1,
                          alt: "Bidmea platform",
                          desc: 'The bidding platform'
                      },{
                          src: imgBidmea2,
                          srcView: imgBidmea2,
                          alt: "Property Developer workflow",
                          desc: 'As a property developer, feel free to make any request as your need'
                      },{
                          src: imgBidmea3,
                          srcView: imgBidmea3,
                          alt: "Supplier workflow",
                          desc: 'As a supplier, you could find your potential customers on the platform'
                      }
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <NavPills
                  color="primary"
                  tabs={[
                    {
                      tabButton: "Overview",
                      // tabIcon: Dashboard,
                      tabContent: (
                        <span>
                          <p>
                            Australia’s newest wholesale appliance bidding platform
                          </p>
                          <br />
                          <p>
                            Feel free to <a href={'http://dev.bidmea.com/'} target={'_blank'}>become a supplier or property developer </a>
                            and start bidding now.
                          </p>
                        </span>
                      )
                    },
                    {
                      tabButton: "Technologies",
                      // tabIcon: Schedule,
                      tabContent: (
                        <span>
                          <ul>
                            <li>ReactJS</li>
                            <li>ExpressJS</li>
                            <li>SendGrid email service</li>
                            <li>MongoDB</li>
                            <li>Circle CI</li>
                          </ul>
                        </span>
                      )
                    }
                  ]}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Minnano Trading Platform</h3>
                </div>
                <ProjectImagesCarousel
                  handleProjectImageClicked={this.handleProjectImageClicked.bind(this)}
                  images={[
                    {
                      src: imgBoFe,
                      srcView: imgBoFe,
                      alt: 'Binary Option Trading Tool',
                      desc: 'Binary Option trading tool is made on web (lot of stuffs relating HTML Canvas here)'
                    },{
                      src: imgMypage1,
                      srcView: imgMypage1,
                      alt: 'My Page - Account Management System',
                      desc: 'Account Managment where users could monitor their account and balance'
                    },{
                      src: imgMypage2,
                      srcView: imgMypage2,
                      alt: 'My Page - Money Transfering',
                      desc: 'Money Transfering where users could transfer their balance between accounts (FX, Option, Social)'
                    },{
                      src: imgBoBe,
                      srcView: imgBoBe,
                      alt: 'BO Admin',
                      desc: 'Binary Option admin panel'
                    },{
                      src: imgDealing,
                      srcView: imgDealing,
                      alt: 'Dealing Admin',
                      desc: 'Dealing admin panel, tool for dealers to get jobs well-done'
                    }
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <NavPills
                  color="primary"
                  tabs={[
                    {
                      tabButton: "Overview",
                      // tabIcon: Dashboard,
                      tabContent: (
                        <span>
                          <p>
                            Top 10 Japanese Forex trading platform powered by <a href={'http://www.traderssec.com/en/profile/'} target={'_blank'}>Traders Securities Co., Ltd</a> with services provided:
                          </p>
                          <ul>
                            <li><a href={'https://min-fx.jp/support/tool/'} target={'_blank'}>Traditional security exchange</a></li>
                            <li>Social Trading</li>
                            <li><a href={'https://min-fx.jp/lineup/op/tool/optiontrader/'} target={'_blank'}>Binary Option</a></li>
                          </ul>
                        </span>
                      )
                    },
                    {
                      tabButton: "Technologies",
                      // tabIcon: Schedule,
                      tabContent: (
                        <span>
                          <ul>
                            <li>Struts</li>
                            <li>Spring MVC</li>
                            <li>Active MQ</li>
                            <li>Web Socket</li>
                            <li>AngularJS</li>
                            <li>MySQL</li>
                            <li>Redis</li>
                            <li>Neo4j</li>
                            <li>Jenkins</li>
                            <li>Core trading integration: <a href={'https://www.metatrader4.com/en'} target={'_blank'}>MT4</a></li>
                            <li>Payment gateway integration: Billing Japan (BJP)</li>
                          </ul>
                        </span>
                      )
                    }
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <ModalGateway className={'img-viewer-modal-gateway'}>
          {viewerIsOpen ? (
            <Modal onClose={this.closeLightbox.bind(this)} className={'img-viewer-modal'}>
              <Carousel
                className={'img-viewer-carousel'}
                currentIndex={currentImageIndex}
                views={photos ? photos.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.desc
                })) : []}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(SectionProjects);
