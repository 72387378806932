/*eslint-disable*/
                import React from "react";
                // react components for routing our app without refresh
                import { Link, withRouter } from "react-router-dom";

                // @material-ui/core components
                import withStyles from "@material-ui/core/styles/withStyles";
                import List from "@material-ui/core/List";
                import ListItem from "@material-ui/core/ListItem";
                import Tooltip from "@material-ui/core/Tooltip";
                import Hidden from "@material-ui/core/Hidden";
                // @material-ui/icons
                import { Apps, CloudDownload } from "@material-ui/icons";

                // core components
                import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
                import Button from "components/CustomButtons/Button.jsx";
                import ReactGa from "analytics/GaAnalytics";

                import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

                function HeaderLinks({ ...props }) {
                const { classes } = props;
                return (
                <List className={classes.list}>
                <Hidden smDown>
                <ListItem className={classes.listItem}>
                <CustomDropdown
                noLiPadding
                buttonText="portfolio"
                buttonProps={{
                    className: classes.navLink,
                    color: "transparent"
                }}
                buttonIcon={Apps}
                dropdownList={[
                    <Link to="/commercial-projects" className={classes.dropdownLink}>
                        Commercial Projects
                    </Link>,
                    <Link to="/my-own-projects" className={classes.dropdownLink}>
                        My Own Projects
                    </Link>,
                    <Link to="/licences-certificates" className={classes.dropdownLink}>
                        Licences and Certificates
                    </Link>,
                    <Link to="/" className={classes.dropdownLink}>
                        Skill Set
                    </Link>
          ]}
        />
      </ListItem>
      </Hidden>
      <ListItem className={classes.listItem}>
        <Button
          href={require('assets/pdf/chiennghe-info-cv.pdf')}
          color="transparent"
          target="_blank"
          className={classes.navLink}
        >
          <CloudDownload className={classes.icons} /> My CV
        </Button>
      </ListItem>
      <Hidden mdUp>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="commercial-projects"
          title="Commercial Projects"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            // href="https://github.com/nghexuanchien"
            // target="_blank"
            color="transparent"
            className={classes.navLink}
            onClick={() => { ReactGa.event({
                category: 'Navigation',
                action: 'Click Commercial Projects Button',
                label: 'Commercial Project Button'
              });
              props.history.push('/commercial-projects');
            }}
          >
            <i className={classes.socialIcons + " fas fa-boxes"} />
            <Hidden mdUp>
              {' '} Commercial Projects
            </Hidden>
          </Button>
        </Tooltip>
      </ListItem>
      </Hidden>
      <Hidden mdUp>
        <ListItem className={classes.listItem}>
          <Tooltip
            id="my-own-projects"
            title="My Own Projects"
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              // href="https://github.com/nghexuanchien"
              // target="_blank"
              color="transparent"
              className={classes.navLink}
              onClick={() => { ReactGa.event({
                category: 'Navigation',
                action: 'Click My Own Projects Button',
                label: 'My Own Project Button'
              });
                props.history.push('/my-own-projects');
              }}
            >
              <i className={classes.socialIcons + " fas fa-boxes"} />
              <Hidden mdUp>
                {' '} My Own Projects
              </Hidden>
            </Button>
          </Tooltip>
        </ListItem>
      </Hidden>
        <Hidden mdUp>
            <ListItem className={classes.listItem}>
                <Tooltip
                    id="my-licences-and-certificates"
                    title="Licences and Certificates"
                    placement={window.innerWidth > 959 ? "top" : "left"}
                    classes={{ tooltip: classes.tooltip }}
                >
                    <Button
                        // href="https://github.com/nghexuanchien"
                        // target="_blank"
                        color="transparent"
                        className={classes.navLink}
                        onClick={() => { ReactGa.event({
                            category: 'Navigation',
                            action: 'Click Licences and Certificates Button',
                            label: 'Licences and Certificates'
                        });
                            props.history.push('/licences-certificates');
                        }}
                    >
                        <i className={classes.socialIcons + " fas fa-boxes"} />
                        <Hidden mdUp>
                            {' '} Licences and Certificates
                        </Hidden>
                    </Button>
                </Tooltip>
            </ListItem>
        </Hidden>
        <Hidden mdUp>
            <ListItem className={classes.listItem}>
                <Tooltip
                    id="my-skill-set"
                    title="Skill Set"
                    placement={window.innerWidth > 959 ? "top" : "left"}
                    classes={{ tooltip: classes.tooltip }}
                >
                    <Button
                        // href="https://github.com/nghexuanchien"
                        // target="_blank"
                        color="transparent"
                        className={classes.navLink}
                        onClick={() => { ReactGa.event({
                            category: 'Navigation',
                            action: 'Click Skill Set Button',
                            label: 'Skill Set'
                        });
                            props.history.push('/');
                        }}
                    >
                        <i className={classes.socialIcons + " fas fa-boxes"} />
                        <Hidden mdUp>
                            {' '} Skill Set
                        </Hidden>
                    </Button>
                </Tooltip>
            </ListItem>
        </Hidden>
        <ListItem className={classes.listItem}>
            <Tooltip
                id="personal-github"
                title="My Github"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}
            >
                <Button
                    href="https://github.com/nghexuanchien"
                    target="_blank"
                    color="transparent"
                    className={classes.navLink}
                    onClick={() => { ReactGa.event({
                      category: 'Navigation',
                      action: 'Click Github Button',
                      label: 'Github Button'
                    }); }}
                >
                    <i className={classes.socialIcons + " fab fa-github"} />
                    <Hidden mdUp>
                        {' '} My Github
                    </Hidden>
                </Button>
            </Tooltip>
        </ListItem>
        <ListItem className={classes.listItem}>
            <Tooltip
                id="personal-linkedin"
                title="My LinkedIn"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}
            >
                <Button
                    href="https://www.linkedin.com/in/chien-nghe/"
                    target="_blank"
                    color="transparent"
                    className={classes.navLink}
                    onClick={() => { ReactGa.event({
                        category: 'Navigation',
                        action: 'Click LinkedIn Button',
                        label: 'LinkedIn Button'
                    }); }}
                >
                    <i className={classes.socialIcons + " fab fa-linkedin"} />
                    <Hidden mdUp>
                        {' '} My LinkedIn
                    </Hidden>
                </Button>
            </Tooltip>
        </ListItem>
        {/*<ListItem className={classes.listItem}>
          <Tooltip
            id="personal-stackoverflow"
            title="My Stack Overflow"
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              href="https://stackoverflow.com/users/story/3946410"
              target="_blank"
              color="transparent"
              className={classes.navLink}
              onClick={() => { ReactGa.event({
                category: 'Navigation',
                action: 'Click StackOverflow Button',
                label: 'StackOverflow Button'
              }); }}
            >
              <i className={classes.socialIcons + " fab fa-stack-overflow"} />
              <Hidden mdUp>
                {' '} My Stack Overflow
              </Hidden>
            </Button>
          </Tooltip>
        </ListItem>*/}
        <ListItem className={classes.listItem}>
            <Tooltip
                id="personal-email"
                title="Email me"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}
            >
                <Button
                    href="mailto:chienptit@gmail.com?Subject=Hello%20Chien%20Nghe"
                    target="_top"
                    color="transparent"
                    className={classes.navLink}
                    onClick={() => { ReactGa.event({
                      category: 'Navigation',
                      action: 'Click Email Button',
                      label: 'Email Button'
                    }); }}
                >
                    <i className={classes.socialIcons + " fas fa-envelope"} />
                    <Hidden mdUp>
                        {' '} Email me
                    </Hidden>
                </Button>
            </Tooltip>
        </ListItem>
      {/*<ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-twitter"
          title="Follow us on twitter"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://twitter.com/CreativeTim"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-twitter"} />
          </Button>
        </Tooltip>
      </ListItem>*/}
      {/*<ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Follow us on facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/CreativeTim"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem>*/}
      {/*<ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Follow us on instagram"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com/CreativeTimOfficial"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-instagram"} />
          </Button>
        </Tooltip>
      </ListItem>*/}
    </List>
  );
}

export default withRouter(withStyles(headerLinksStyle)(HeaderLinks));
