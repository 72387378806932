import React from "react";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Cover from 'react-video-cover';

import componentsStyle from "assets/jss/material-kit-react/views/components.jsx";
// import SectionOwnProjects from "./Sections/SectionOwnProjects";
import SectionLicenceCertificates from './Sections/SectionLicenceCertificates';
import ReactGa from "analytics/GaAnalytics";

class LicenceCertificates extends React.Component {
  componentDidMount(){
    // console.log(`call to google analytic ... `);
    ReactGa.pageview(window.location.pathname + window.location.search);
  }

  render() {
    const { classes, ...rest } = this.props;
    const imageClasses = classNames(
        classes.imgRaised,
        classes.imgRoundedCircle,
        classes.imgFluid
    );

    const videoOptions = {
      src: require("assets/video/bg6.mov"),
      autoPlay: true,
      muted: true,
      loop: true,
      playsInline: true
    };

    return (
      <div>
        <div className="bg-video">
            <Cover
                videoOptions={videoOptions}
                remeasureOnWindowResize
                getResizeNotifier={resizeNotifier => {
                }}
            />
        </div>
        <Header
          brand="Chien Nghe"
          leftTo={'/'}
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />

        <Parallax className={'page-header'}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem>
                <div className={classNames(classes.brand, 'center-content')}>
                  <div className="avatar">
                    <img src={require("assets/img/faces/chiennghe2.jpg")} alt="..." className={imageClasses} />
                  </div>
                  <h3 className={classNames(classes.subtitle, 'header-designation')}>
                    Full-Stack Developer
                  </h3>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised, 'content-section', 'first')}>
          <SectionLicenceCertificates />
        </div>

        <Footer />
      </div>
    );
  }
}

export default withStyles(componentsStyle)(LicenceCertificates);
