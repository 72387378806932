import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import classNames from "classnames";
import basicsStyle from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.jsx";
import imgPos1 from "assets/img/retailshop/pos_in_action.jpg";
import imgPos1_h from "assets/img/retailshop/pos_in_action_h.jpg";
import imgPos2 from "assets/img/retailshop/pos_sale_panel.jpg";
import imgPos3 from "assets/img/retailshop/pos_order_list.jpg";
import imgPos4 from "assets/img/retailshop/pos_order_details.jpg";
import imgPos5 from "assets/img/retailshop/pos_debt_management.jpg";
import imgPos6 from "assets/img/retailshop/pos_order_printing.jpg";
import imgPos6_h from "assets/img/retailshop/pos_order_printing_h.jpg";
import imgPos7 from "assets/img/retailshop/pos_product_management.jpg";
import imgPos8 from "assets/img/retailshop/pos_barcode_printing.jpg";
// import imgPos9 from "assets/img/pos/pos_barcode_printing1.jpg";
import imgPos10 from "assets/img/retailshop/pos_customer_management.jpg";
import imgPos11 from "assets/img/retailshop/pos_data_recovery.jpg";

import NavPills from "components/NavPills/NavPills.jsx";
import ProjectImagesCarousel from './Partials/ProjectImagesCarousel'
import Carousel, { Modal, ModalGateway } from "react-images";

class SectionOwnProjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewerIsOpen: false
    };
  }
  componentDidMount() {
  }

  handleProjectImageClicked(image, index, images){
    // console.log(`handleProjectImageClicked - image: ${image}, index: ${index}, images: `, images);
    this.setState({
      photos: images,
      currentImageIndex: index,
      viewerIsOpen: true
    })
  }

  closeLightbox() {
    this.setCurrentImage.bind(this)(0);
    this.setViewerIsOpen.bind(this)(false);
  };

  setCurrentImage(imgindex){
    this.setState({
      currentImageIndex: imgindex
    })
  }

  setViewerIsOpen(opened){
    this.setState({
      viewerIsOpen: opened
    })
  }

  render() {
    const { classes } = this.props;
    const { photos, currentImageIndex } = this.state;
    let viewerIsOpen = this.state.viewerIsOpen;
    // console.log(`the photos in renders `, photos)
    if(!photos || !photos.length){
      viewerIsOpen = false;
    }

    // console.log(`the photos in renders - viewerIsOpen: ${viewerIsOpen}, currentImageIndex: ${currentImageIndex}`)

    return (
      <div className={classNames(classes.sections, 'skillset-wrapper')}>
        <div className={classes.container}>
          <div className={classes.title}>
            <h2>My Own Projects</h2>
          </div>

          <div id="progress">
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Point of Sale for retailers</h3>
                </div>
                <ProjectImagesCarousel
                  handleProjectImageClicked={this.handleProjectImageClicked.bind(this)}
                  images={[
                    {
                      src: imgPos1_h,
                      srcView: imgPos1,
                      alt: 'POS in action',
                      desc: 'This is how it is currently used in my family store'
                    },{
                      src: imgPos2,
                      srcView: imgPos2,
                      alt: "Sale Panel",
                      desc: 'Sale panel is able to handle orders for multiple clients at the same time. Most recent sold products for quick pickup'
                    },{
                      src: imgPos3,
                      srcView: imgPos3,
                      alt: "Order Management",
                      desc: 'List of orders sold, being able to be filtered by day for seeing revenue'
                    },{
                      src: imgPos4,
                      srcView: imgPos4,
                      alt: "Order Details",
                      desc: 'Details of a particular with list of items sold'
                    },{
                      src: imgPos5,
                      srcView: imgPos5,
                      alt: "Debt Order",
                      desc: 'Orders in debt is separately managed for ease of management'
                    },{
                      src: imgPos6_h,
                      srcView: imgPos6,
                      alt: "Order Bill",
                      desc: 'Order bill is printed by integrating with heat printer'
                    },{
                      src: imgPos7,
                      srcView: imgPos7,
                      alt: "Product Management",
                      desc: 'List of products for sale is managed by barcode. Facilities for updating product information'
                    },{
                      src: imgPos8,
                      srcView: imgPos8,
                      alt: "Barcode printing",
                      desc: 'Product barcode as sticky labels are printed by integrating with barcode printer.'
                    },{
                      src: imgPos10,
                      srcView: imgPos10,
                      alt: "Customer Management",
                      desc: 'List of customers are maintained also with the distinction as individual or other retailers'
                    },{
                      src: imgPos11,
                      srcView: imgPos11,
                      alt: "Data Recovery",
                      desc: 'Data is saved to the cloud server and could be downloaded for automatically recovery at anytime'
                    }
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <NavPills
                  color="primary"
                  tabs={[
                    {
                      tabButton: "Overview",
                      // tabIcon: Dashboard,
                      tabContent: (
                        <span>
                          <p>
                            This POS is currently used in my family store. Save my mom's time and effort to remember everything for sale.
                          </p>
                          <p>I also built an admin site (by AngularJS) for managing products, orders and customers.
                            The admin site also has functionality to activate or reject registration from a particular POS station requesting.
                          </p>
                          <p>
                            A small mobile app (by React Native) was also made for seller to see online products, orders happened in their store by the POS.
                          </p>
                        </span>
                      )
                    },
                    {
                      tabButton: "Technologies",
                      // tabIcon: Schedule,
                      tabContent: (
                        <span>
                          <ul>
                            <li>JavaFX</li>
                            <li>Spring Boot</li>
                            <li>Derby</li>
                          </ul>
                        </span>
                      )
                    }
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <ModalGateway className={'img-viewer-modal-gateway'}>
          {viewerIsOpen ? (
            <Modal onClose={this.closeLightbox.bind(this)} className={'img-viewer-modal'}>
              <Carousel
                className={'img-viewer-carousel'}
                currentIndex={currentImageIndex}
                views={photos ? photos.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.desc
                })) : []}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(SectionOwnProjects);
