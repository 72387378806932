import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import LinearProgress from "@material-ui/core/LinearProgress";
// core components
import customLinearProgressStyle from "assets/jss/material-kit-react/components/customLinearProgressStyle.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import classNames from 'classnames';

function SkillMeter({ ...props }) {
  const { classes, color, skillname, logos, ...rest } = props;
  return (
    <div className={'skillmeter'}>
      <div className={'skill-info'}>
        <div className={'skillname'}>{skillname}</div>
        <div className={'skilllogos'}>
          {logos && logos.length && logos.map(item => {
              let logoItemStyle = {};
              if(item.logoWidth){
                logoItemStyle.width = item.logoWidth;
              }
              return (
                  <div className={'logo-item'} key={item.id} style={logoItemStyle}>
                    <Tooltip
                        id={item.id}
                        title={item.tooltipTitle}
                        placement={item.tooltipPosition}
                        // classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                          href={item.linkReference}
                          target="_blank"
                          color="transparent"
                          className={classNames(classes.navLink, 'logo-button')}
                      >
                        <img src={item.logo} style={item.logoScale? {transform: `scale(${item.logoScale})`} : {}} alt={''}/>
                      </Button>
                    </Tooltip>
                  </div>
              )
          })}
        </div>
      </div>

      <LinearProgress
        {...rest}
        classes={{
          root: classes.root + " " + classes[color + "Background"],
          bar: classes.bar + " " + classes[color]
        }}
      />
    </div>
  );
}

SkillMeter.defaultProps = {
  color: "gray"
};

SkillMeter.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ])
};

export default withStyles(customLinearProgressStyle)(SkillMeter);
