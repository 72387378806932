import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import classNames from "classnames";
import basicsStyle from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.jsx";
import imgCertGcpCore from 'assets/img/certificates/cert_gcp_core.png';
import NavPills from "components/NavPills/NavPills.jsx";
import ProjectImagesCarousel from './Partials/ProjectImagesCarousel'
import Carousel, { Modal, ModalGateway } from "react-images";

class SectionLicenceCertificates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewerIsOpen: false
    };
  }
  componentDidMount() {
  }

  handleProjectImageClicked(image, index, images){
    this.setState({
      photos: images,
      currentImageIndex: index,
      viewerIsOpen: true
    })
  }

  closeLightbox() {
    this.setCurrentImage.bind(this)(0);
    this.setViewerIsOpen.bind(this)(false);
  };

  setCurrentImage(imgindex){
    this.setState({
      currentImageIndex: imgindex
    })
  }

  setViewerIsOpen(opened){
    this.setState({
      viewerIsOpen: opened
    })
  }

  render() {
    const { classes } = this.props;
    const { photos, currentImageIndex } = this.state;
    let viewerIsOpen = this.state.viewerIsOpen;
    // console.log(`the photos in renders `, photos)
    if(!photos || !photos.length){
      viewerIsOpen = false;
    }

    // console.log(`the photos in renders - viewerIsOpen: ${viewerIsOpen}, currentImageIndex: ${currentImageIndex}`)

    return (
      <div className={classNames(classes.sections, 'skillset-wrapper')}>
        <div className={classes.container}>
          <div className={classes.title}>
            <h2>My Licences and Certificates</h2>
          </div>

          <div id="progress">
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.title}>
                  <h3>Google Cloud Platform Fundamentals: Core Infrastructure</h3>
                </div>
                <ProjectImagesCarousel
                  handleProjectImageClicked={this.handleProjectImageClicked.bind(this)}
                  images={[
                    {
                      src: imgCertGcpCore,
                      srcView: imgCertGcpCore,
                      alt: ' Google Cloud Platform Fundamentals: Core Infrastructure',
                      desc: `Google Cloud Platform Fundamentals: Core Infrastructure. Issued Dec 2019. Credential ID GR4LKRWQ6Z9J`
                    }
                  ]}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <NavPills
                  color="primary"
                  tabs={[
                    {
                      tabButton: "Content",
                      // tabIcon: Dashboard,
                      tabContent: (
                        <span>
                          <p>Issued Dec 2019</p>
                          <p>
                            Credential ID <a href={'https://www.coursera.org/account/accomplishments/verify/GR4LKRWQ6Z9J'} target={'_blank'}>GR4LKRWQ6Z9J</a>
                          </p>
                          <p>This course introduces you to important concepts and terminology for working with Google Cloud Platform (GCP). You learn about, and compare, many of the computing and storage services available in Google Cloud Platform, including Google App Engine, Google Compute Engine, Google Kubernetes Engine, Google Cloud Storage, Google Cloud SQL, and BigQuery. You learn about important resource and policy management tools, such as the Google Cloud Resource Manager hierarchy and Google Cloud Identity and Access Management. Hands-on labs give you foundational skills for working with GCP.</p>
                        </span>
                      )
                    },
                    /*{
                      tabButton: "Content",
                      // tabIcon: Schedule,
                      tabContent: (
                        <span>
                          <p>This course introduces you to important concepts and terminology for working with Google Cloud Platform (GCP). You learn about, and compare, many of the computing and storage services available in Google Cloud Platform, including Google App Engine, Google Compute Engine, Google Kubernetes Engine, Google Cloud Storage, Google Cloud SQL, and BigQuery. You learn about important resource and policy management tools, such as the Google Cloud Resource Manager hierarchy and Google Cloud Identity and Access Management. Hands-on labs give you foundational skills for working with GCP.</p>
                        </span>
                      )
                    }*/
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <ModalGateway className={'img-viewer-modal-gateway'}>
          {viewerIsOpen ? (
            <Modal onClose={this.closeLightbox.bind(this)} className={'img-viewer-modal'}>
              <Carousel
                className={'img-viewer-carousel'}
                currentIndex={currentImageIndex}
                views={photos ? photos.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.desc
                })) : []}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(SectionLicenceCertificates);
