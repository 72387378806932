import React from "react";
// plugin that creates slider
// import nouislider from "nouislider";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
// import Radio from "@material-ui/core/Radio";
// import Switch from "@material-ui/core/Switch";
// @material-ui/icons
// import Favorite from "@material-ui/icons/Favorite";
// import People from "@material-ui/icons/People";
// import Check from "@material-ui/icons/Check";
// import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// import Button from "components/CustomButtons/Button.jsx";
// import CustomInput from "components/CustomInput/CustomInput.jsx";
// import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";
// import Paginations from "components/Pagination/Pagination.jsx";
// import Badge from "components/Badge/Badge.jsx";
import classNames from "classnames";
import Hidden from "@material-ui/core/Hidden";
import basicsStyle from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.jsx";
import SkillMeter from "../../../components/SkillMeter/SkillMeter";

class SectionSkillSet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [24, 22],
      selectedEnabled: "b",
      checkedA: true,
      checkedB: false
    };
    this.handleChangeEnabled = this.handleChangeEnabled.bind(this);
  }
  componentDidMount() {
    /*nouislider.create(this.refs.slider1, {
      start: [40],
      connect: [true, false],
      step: 1,
      range: { min: 0, max: 100 }
    });
    nouislider.create(this.refs.slider2, {
      start: [20, 60],
      connect: [false, true, false],
      step: 1,
      range: { min: 0, max: 100 }
    });*/
  }
  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };
  handleChangeEnabled(event) {
    this.setState({ selectedEnabled: event.target.value });
  }
  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classNames(classes.sections, 'skillset-wrapper')}>
        <div className={classes.container}>
          <div className={classes.title}>
            <h2>Programming Skills</h2>
          </div>

          <div id="progress">
            <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                    <div className={classes.title}>
                      <h3>Web Frontend JS Frameworks</h3>
                    </div>
                    <SkillMeter
                      variant="determinate"
                      color="primary"
                      skillname="AngularJS"
                      value={80}
                      logos={[
                          {
                              id: 'angularjs-core',
                              tooltipTitle: 'Angular core',
                              tooltipPosition: 'top',
                              linkReference: 'https://angularjs.org/',
                              logo: require("assets/img/logos/logo_angularjs.svg")
                          },
                          {
                              id: 'angularjs-ngrx',
                              tooltipTitle: 'NGRX',
                              tooltipPosition: 'top',
                              linkReference: 'https://ngrx.io/',
                              logo: require("assets/img/logos/logo_ngrx.svg")
                          },
                          {
                              id: 'angularjs-universal',
                              tooltipTitle: 'Universal',
                              tooltipPosition: 'top',
                              linkReference: 'https://angular.io/guide/universal',
                              logo: require("assets/img/logos/logo_angularuniversal.png"),
                              logoScale: 0.9
                          }
                      ]}
                    />

                    <SkillMeter
                      variant="determinate"
                      color="primary"
                      skillname="ReactJS"
                      value={90}
                      logos={[
                          {
                              id: 'reactjs-core',
                              tooltipTitle: 'React core',
                              tooltipPosition: 'top',
                              linkReference: 'https://reactjs.org/',
                              logo: require("assets/img/logos/logo_reactjs.svg"),
                              logoScale: 1.5
                          },
                          {
                              id: 'reactjs-redux',
                              tooltipTitle: 'Redux',
                              tooltipPosition: 'top',
                              linkReference: 'https://redux.js.org/',
                              logo: require("assets/img/logos/logo_redux.svg"),
                              // logoScale: 1.5
                          },
                          {
                              id: 'reactjs-nextjs',
                              tooltipTitle: 'NextJS',
                              tooltipPosition: 'top',
                              linkReference: 'https://nextjs.org/',
                              logo: require("assets/img/logos/logo_nextjs.svg"),
                              // logoScale: 1.5
                          }
                      ]}
                    />
                    <SkillMeter
                      variant="determinate"
                      color="primary"
                      skillname="EmberJS"
                      value={70}
                      logos={[
                          {
                              id: 'emberjs-core',
                              tooltipTitle: 'Ember Core',
                              tooltipPosition: 'top',
                              linkReference: 'https://emberjs.com/',
                              logo: require("assets/img/logos/logo_emberjs.svg"),
                              logoWidth: '2.5rem'
                          },
                          {
                              id: 'emberjs-data',
                              tooltipTitle: 'Ember Data',
                              tooltipPosition: 'top',
                              linkReference: 'https://github.com/emberjs/data',
                              logo: require("assets/img/logos/logo_emberdata.svg"),
                              logoWidth: '4rem'
                          }
                      ]}
                    />
                      <SkillMeter
                          variant="determinate"
                          color="primary"
                          skillname="VueJS"
                          value={40}
                          logos={[
                              {
                                  id: 'vuejs-core',
                                  tooltipTitle: 'VueJS Core',
                                  tooltipPosition: 'top',
                                  linkReference: 'https://vuejs.org/',
                                  logo: require("assets/img/logos/logo_vuejs.png")
                              },
                              {
                                  id: 'vuejs-vuex',
                                  tooltipTitle: 'Vuex',
                                  tooltipPosition: 'top',
                                  linkReference: 'https://vuex.vuejs.org/',
                                  logo: require("assets/img/logos/logo_vuex.png")
                              },
                              {
                                  id: 'vuejs-nuxt',
                                  tooltipTitle: 'NuxtJS',
                                  tooltipPosition: 'top',
                                  linkReference: 'https://nuxtjs.org/',
                                  logo: require("assets/img/logos/logo_nuxtjs.jpg"),
                                  logoScale: 1.4
                              }
                          ]}
                      />
                  </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <Hidden smDown>
                        <div className={classNames(classes.title)}>
                            <h3>&nbsp;</h3>
                        </div>
                    </Hidden>
                    <p>
                        Since the trend of Single Page Application (SPA) website exploded, I have chances to work on cutting-edge javascript frameworks spreading from
                        complicated configuration as ReactJS to highly conventional-based like EmberJS.
                    </p>
                    <p>
                        Though there are numerous different frameworks, they have things in common. In my opinion, some aspects
                        a developer has to consider when playing with such framework are
                    </p>
                    <ul>
                      <li>Component-based approach when making up pages</li>
                      <li>Single source of trust for application state management</li>
                      <li>Server side rendering support for SEO</li>
                    </ul>
                    <p>With the mindset, I believe we could easily switch between frameworks, reduce the slope of learning-curve.</p>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                    <div className={classes.title}>
                        <h3>Mobile Apps</h3>
                    </div>
                    <SkillMeter
                        variant="determinate"
                        color="primary"
                        skillname="React Native"
                        value={50}
                        logos={[
                            {
                                id: 'mobile-reactnative',
                                tooltipTitle: 'React Native',
                                tooltipPosition: 'top',
                                linkReference: 'https://facebook.github.io/react-native/',
                                logo: require("assets/img/logos/logo_reactnative.png"),
                                logoScale: 2
                            }
                        ]}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <Hidden smDown>
                        <div className={classNames(classes.title)}>
                            <h3>&nbsp;</h3>
                        </div>
                    </Hidden>
                    <p>
                        Mobile application development is not my main background, however I have involved in a real project
                        with adoption of React Native. CrewStudio application is now production-ready, lives on both{' '}
                        <a href={'https://itunes.apple.com/vn/app/crewstudio/id1398899998'} target={'_blank'}>IOS</a>{' '}
                        and <a href={'https://play.google.com/store/apps/details?id=com.crewstudio.app'} target={'_blank'}>Android</a> which
                        inspires me a lot for more interest on field of development in the future.
                    </p>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                    <div className={classes.title}>
                        <h3>Java stack</h3>
                    </div>
                    <SkillMeter
                        variant="determinate"
                        color="primary"
                        skillname="Spring frameworks"
                        value={80}
                        logos={[
                            {
                                id: 'spring-core',
                                tooltipTitle: 'Spring Core',
                                tooltipPosition: 'top',
                                linkReference: 'https://spring.io/',
                                logo: require("assets/img/logos/logo_spring.svg")
                            },
                            {
                                id: 'spring-springboot',
                                tooltipTitle: 'Spring Boot',
                                tooltipPosition: 'top',
                                linkReference: 'https://spring.io/projects/spring-boot',
                                logo: require("assets/img/logos/logo_springboot.svg")
                            },
                            {
                                id: 'spring-projectreactor',
                                tooltipTitle: 'Project Reactor',
                                tooltipPosition: 'top',
                                linkReference: 'https://projectreactor.io/',
                                logo: require("assets/img/logos/logo_projectreactor.png")
                            }
                        ]}
                    />

                    <SkillMeter
                        variant="determinate"
                        color="primary"
                        skillname="Struts"
                        value={80}
                        logos={[
                            {
                                id: 'struts-core',
                                tooltipTitle: 'Apache Struts',
                                tooltipPosition: 'top',
                                linkReference: 'https://struts.apache.org/',
                                logo: require("assets/img/logos/logo_struts.png"),
                                logoScale: 1.4
                            }
                        ]}
                    />

                    <SkillMeter
                        variant="determinate"
                        color="primary"
                        skillname="Servlet container"
                        value={90}
                        logos={[
                            {
                                id: 'container-tomcat',
                                tooltipTitle: 'Apache Tomcat',
                                tooltipPosition: 'top',
                                linkReference: 'http://tomcat.apache.org/',
                                logo: require("assets/img/logos/logo_tomcat.png")
                            }
                        ]}
                    />
                    <SkillMeter
                        variant="determinate"
                        color="primary"
                        skillname="Search Engine"
                        value={80}
                        logos={[
                            {
                                id: 'search-elastic',
                                tooltipTitle: 'Elastic Search',
                                tooltipPosition: 'top',
                                linkReference: 'https://www.elastic.co/',
                                logo: require("assets/img/logos/logo_elasticsearch.png"),
                                logoScale:2
                            }
                        ]}
                    />
                    <SkillMeter
                        variant="determinate"
                        color="primary"
                        skillname="Big Data"
                        value={60}
                        logos={[
                            {
                                id: 'bigdata-hadoop',
                                tooltipTitle: 'Apache Hadoop',
                                tooltipPosition: 'top',
                                linkReference: 'https://hadoop.apache.org/',
                                logo: require("assets/img/logos/logo_hadoop.png")
                            },
                            {
                                id: 'bigdata-spark',
                                tooltipTitle: 'Apache Spark',
                                tooltipPosition: 'top',
                                linkReference: 'https://spark.apache.org/',
                                logo: require("assets/img/logos/logo_spark.png"),
                                logoWidth: "2.5rem"
                            }
                        ]}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <Hidden smDown>
                        <div className={classNames(classes.title)}>
                            <h3>&nbsp;</h3>
                        </div>
                    </Hidden>
                    <p>
                        Java is really my blade, makes me feel confident and strong. I started out with the language since
                        very first time of programming. I experienced the language and its frameworks ecosystem during the industry career.
                    </p>
                    <p>
                        I joined a <a href={'https://www.nextop.asia/'} target={'_blank'}>Fintech company</a> more than 4 years
                        and became an integral part of their system. That time not only sharps my Java skill
                        but also built up the mindset for how to develop large-scale, real-time applications.
                    </p>
                    <p>
                        As an interest on Spring and it's ecosystem, I would like to keep up with the latest stuffs of the framework,
                        also did customizations and exploited their benefits for building a Point Of Sale (POS) system currently used in my family store.
                    </p>
                    <p>
                        Though Java (JVM) seems to be old in comparison with some modern platforms like NodeJS or Python, I am still impressed by
                        its strong performance and promising future evolution of the language syntax.
                    </p>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                    <div className={classes.title}>
                        <h3>NodeJS stack</h3>
                    </div>
                    <SkillMeter
                        variant="determinate"
                        color="primary"
                        skillname="ExpressJS"
                        value={90}
                        logos={[
                            {
                                id: 'nodejs-expressjs',
                                tooltipTitle: 'Express JS / MEAN stack',
                                tooltipPosition: 'top',
                                linkReference: 'https://expressjs.com/',
                                logo: require("assets/img/logos/logo_meanstack.png"),
                                logoWidth: '3rem'
                            }
                        ]}
                    />
                    <SkillMeter
                        variant="determinate"
                        color="primary"
                        skillname="Ghost CMS"
                        value={90}
                        logos={[
                            {
                                id: 'nodejs-ghostcms',
                                tooltipTitle: 'Ghost CMS',
                                tooltipPosition: 'top',
                                linkReference: 'https://ghost.org/',
                                logo: require("assets/img/logos/logo_ghostcms.jpg"),
                                logoScale: 2
                            }
                        ]}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <Hidden smDown>
                        <div className={classNames(classes.title)}>
                            <h3>&nbsp;</h3>
                        </div>
                    </Hidden>
                    <p>
                        NodeJS platform surprised the global community since the time it's released, also I am not an exception.
                        The asynchronous model and the convenience of building full system stack with only single javascript language is absolutely great.
                    </p>
                    <p>
                        By exploring of the platform and joining some production projects, doing customization on open source ones like Ghost CMS. I never stop loving
                        adoption of NodeJS for future development.
                    </p>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                    <div className={classes.title}>
                        <h3>Databases</h3>
                    </div>
                    <SkillMeter
                        variant="determinate"
                        color="primary"
                        skillname="RDBMS"
                        value={80}
                        logos={[
                            {
                                id: 'rdbms-mysql',
                                tooltipTitle: 'MySQL',
                                tooltipPosition: 'top',
                                linkReference: 'https://www.mysql.com/',
                                logo: require("assets/img/logos/logo_mysql.svg")
                            },
                            {
                                id: 'rdbms-derby',
                                tooltipTitle: 'Apache Derby',
                                tooltipPosition: 'top',
                                linkReference: 'https://db.apache.org/derby/',
                                logo: require("assets/img/logos/logo_derby.svg")
                            }
                        ]}
                    />

                    <SkillMeter
                        variant="determinate"
                        color="primary"
                        skillname="NoSQL"
                        value={80}
                        logos={[
                            {
                                id: 'nosql-mysql',
                                tooltipTitle: 'MongoDB',
                                tooltipPosition: 'top',
                                linkReference: 'https://www.mongodb.com/',
                                logo: require("assets/img/logos/logo_mongodb.png")
                            },
                            {
                                id: 'nosql-redis',
                                tooltipTitle: 'Redis',
                                tooltipPosition: 'top',
                                linkReference: 'https://redis.io/',
                                logo: require("assets/img/logos/logo_redis.png")
                            }
                        ]}
                    />

                    <SkillMeter
                        variant="determinate"
                        color="primary"
                        skillname="Cloud Storage"
                        value={70}
                        logos={[
                            {
                                id: 'cloud-s3',
                                tooltipTitle: 'AWS S3',
                                tooltipPosition: 'top',
                                linkReference: 'https://aws.amazon.com/s3/',
                                logo: require("assets/img/logos/logo_awss3.svg")
                            },
                            {
                                id: 'cloud-firebase',
                                tooltipTitle: 'Google Firebase / Firestore',
                                tooltipPosition: 'top',
                                linkReference: 'https://firebase.google.com/',
                                logo: require("assets/img/logos/logo_firebase.png")
                            }
                        ]}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <Hidden smDown>
                        <div className={classNames(classes.title)}>
                            <h3>&nbsp;</h3>
                        </div>
                    </Hidden>
                    <p>
                        In the adventure of software development, I worked with so various types of databases, from RDBMS to NoSQL.
                        The most exciting time is when deciding right database type for addressing problems, sometime the seamless integration between them are required.
                    </p>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                    <div className={classes.title}>
                        <h3>CI / CD / DevOps</h3>
                    </div>
                    <SkillMeter
                        variant="determinate"
                        color="primary"
                        skillname="Version Control"
                        value={90}
                        logos={[
                            {
                                id: 'vc-subversion',
                                tooltipTitle: 'Apache Subversion',
                                tooltipPosition: 'top',
                                linkReference: 'https://subversion.apache.org/',
                                logo: require("assets/img/logos/logo_subversion.jpg")
                            },
                            {
                                id: 'vc-git',
                                tooltipTitle: 'Git',
                                tooltipPosition: 'top',
                                linkReference: 'https://git-scm.com/',
                                logo: require("assets/img/logos/logo_git.png")
                            }
                        ]}
                    />

                    <SkillMeter
                        variant="determinate"
                        color="primary"
                        skillname="CI Servers"
                        value={60}
                        logos={[
                            {
                                id: 'ci-jenkins',
                                tooltipTitle: 'Jenkins',
                                tooltipPosition: 'top',
                                linkReference: 'https://jenkins.io/',
                                logo: require("assets/img/logos/logo_jenkins.png")
                            },
                            {
                                id: 'ci-circleci',
                                tooltipTitle: 'Circle CI',
                                tooltipPosition: 'top',
                                linkReference: 'https://circleci.com/',
                                logo: require("assets/img/logos/logo_circleci.png")
                            }
                        ]}
                    />

                    <SkillMeter
                        variant="determinate"
                        color="primary"
                        skillname="Docker"
                        value={80}
                        logos={[
                            {
                                id: 'docker',
                                tooltipTitle: 'Docker',
                                tooltipPosition: 'top',
                                linkReference: 'https://www.docker.com/',
                                logo: require("assets/img/logos/logo_docker.png")
                            }
                        ]}
                    />
                    <SkillMeter
                        variant="determinate"
                        color="primary"
                        skillname="Kubernetes"
                        value={60}
                        logos={[
                            {
                                id: 'kubernetes',
                                tooltipTitle: 'Kubernetes',
                                tooltipPosition: 'top',
                                linkReference: 'https://kubernetes.io/',
                                logo: require("assets/img/logos/logo_kubernetes.png")
                            }
                        ]}
                    />

                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <Hidden smDown>
                        <div className={classNames(classes.title)}>
                            <h3>&nbsp;</h3>
                        </div>
                    </Hidden>
                    <p>
                        The life of developers (including me) is never easier when doing operation stuffs with automated tools.
                        Docker and Kubernetes are the two I luckily experienced in hand-on projects with cloud solution provided by AWS and Google.
                    </p>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                    <div className={classes.title}>
                        <h3>Other languages</h3>
                    </div>
                    <SkillMeter
                        variant="determinate"
                        color="primary"
                        skillname="Golang"
                        value={30}
                    />

                    <SkillMeter
                        variant="determinate"
                        color="primary"
                        skillname="Python"
                        value={60}
                    />

                    <SkillMeter
                        variant="determinate"
                        color="primary"
                        skillname="PHP"
                        value={60}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <Hidden smDown>
                        <div className={classNames(classes.title)}>
                            <h3>&nbsp;</h3>
                        </div>
                    </Hidden>
                    <p>
                        On the way to becoming a full-stack developer, the more known languages, the better project development.
                        Keeping non-stopping learn habit with enough curiosity, I am exploring and sharping skills in other languages
                        for comprehensive addressing other fields of study like AI, Block Chain and other recent experimental knowledge.
                    </p>
                </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(SectionSkillSet);
