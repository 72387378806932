import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
// import { Link } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// import Button from "components/CustomButtons/Button.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx";
// import SectionBasics from "./Sections/SectionBasics.jsx";
// import SectionNavbars from "./Sections/SectionNavbars.jsx";
// import SectionTabs from "./Sections/SectionTabs.jsx";
// import SectionPills from "./Sections/SectionPills.jsx";
// import SectionNotifications from "./Sections/SectionNotifications.jsx";
// import SectionTypography from "./Sections/SectionTypography.jsx";
// import SectionJavascript from "./Sections/SectionJavascript.jsx";
// import SectionCarousel from "./Sections/SectionCarousel.jsx";
// import SectionCompletedExamples from "./Sections/SectionCompletedExamples.jsx";
// import SectionLogin from "./Sections/SectionLogin.jsx";
// import SectionExamples from "./Sections/SectionExamples.jsx";
// import SectionDownload from "./Sections/SectionDownload.jsx";
import Cover from 'react-video-cover';

import componentsStyle from "assets/jss/material-kit-react/views/components.jsx";
import SectionSkillSet from "./Sections/SectionSkillSet";
// import profilePageStyle from "assets/jss/material-kit-react/views/profilePage.jsx";
import ReactGa from "analytics/GaAnalytics";

class Components extends React.Component {
  componentDidMount(){
    // console.log(`call to google analytic ... `);
    ReactGa.pageview(window.location.pathname + window.location.search);
  }

  render() {
    const { classes, ...rest } = this.props;
    const imageClasses = classNames(
        classes.imgRaised,
        classes.imgRoundedCircle,
        classes.imgFluid
    );

    const videoOptions = {
      src: require("assets/video/bg6.mov"),
      autoPlay: true,
      muted: true,
      loop: true,
      playsInline: true
    };

    return (
      <div>
        <div className="bg-video">
            {/*https://github.com/tkloht/react-video-cover/blob/master/examples/src/pages/background.js*/}
          {/*<video className="bg-video__content" autoPlay muted loop playsInline>
            <source src={require("assets/video/bg6.mov")} type="video/mp4" />
            <source src={require("assets/video/bg6.webm")} type="video/webm" />
            <source src={require("assets/video/bg6.ogv")} type="video/ogg" />
            Your browser is not supported!
          </video>*/}
            <Cover
                videoOptions={videoOptions}
                remeasureOnWindowResize
                getResizeNotifier={resizeNotifier => {
                }}
            />
        </div>
        <Header
          brand="Chien Nghe"
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
        {/*<Parallax image={require("assets/img/bg4.jpg")}>*/}
        <Parallax className={'page-header'}>
          {/*<div className="bg-video">
            <video className="bg-video__content" autoPlay muted loop>
              <source src={require("assets/video/bg6.mov")} type="video/mp4" />
                Your browser is not supported!
            </video>
          </div>*/}
          <div className={classes.container}>
            <GridContainer>
              <GridItem>
                <div className={classNames(classes.brand, 'center-content')}>
                  {/*<h1 className={classes.title}>Material Kit React.</h1>*/}
                  <div className="avatar">
                    <img src={require("assets/img/faces/chiennghe2.jpg")} alt="..." className={imageClasses} />
                  </div>
                  <h3 className={classNames(classes.subtitle, 'header-designation')}>
                    Full-Stack Developer
                  </h3>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised, 'content-section', 'first')}>
          <SectionSkillSet />
        </div>

        {/*<div className={classNames(classes.main, classes.mainRaised, 'content-section')}>
          <SectionBasics />
          <SectionNavbars />
          <SectionTabs />
          <SectionPills />
          <SectionNotifications />
          <SectionTypography />
          <SectionJavascript />
          <SectionCarousel />
          <SectionCompletedExamples />
          <SectionLogin />
          <GridItem md={12} className={classes.textCenter}>
            <Link to={"/login-page"} className={classes.link}>
              <Button color="primary" size="lg" simple>
                View Login Page
              </Button>
            </Link>
          </GridItem>
          <SectionExamples />
          <SectionDownload />
        </div>*/}
        <Footer />
      </div>
    );
  }
}

export default withStyles(componentsStyle)(Components);
